

import { mapState, mapActions } from "pinia";
import { useHomeStore } from "../store";
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {}
      },
      computed: {
        // 此处引入的变量，在各个页面都可以访问到
        ...mapState(useHomeStore, ["token", "user", 'webData']),
      },
      methods: {
        ...mapActions(useHomeStore, ['changeToken', 'changeUser', 'changeWebData']),
        // 退出登录
        mixinLogOut() {
          console.log('退出登录');
          this.changeToken(null)
          this.changeUser(null)
        },
        // 校验登录状态
        mixinCheckLoginStatus() {
          console.log(this.token);
          console.log(this.user);
          if (!this.token && !this.user) {
            this.$router.push("/");
          }
        },
        // 判断终端是否为触摸设备 
        isTouch() {
          if ("ontouchstart" in window) {
            return true
          } else {
            return false
          }
        },
        // 是否是管理员
        isAdmin() {
          let state = false
          if (sessionStorage.getItem("passkey")) {
            if (sessionStorage.getItem("passkey").indexOf('空') < 0) {
              state = true
            }
          }
          // console.log(state);
          return state
        },
        getInfo() {
          this.$http
            .POST(this.$manageUrl.getInfo, {})
            .then((res) => {
              if (res) {
                this.changeUser(res.result.user);
              }
            })
            .catch((error) => {
              console.log(error);
              this.$message.error(error.msg || error.message || "ERROR");
            });
        },
      }
    })
  }
}
