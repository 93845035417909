export default {
    // 测试
    listButton: '按钮',
    // ment
    HomeOutlined: '首页',
    ShopOutlined: '关于我们',
    FileDoneOutlined: '救助案例',
    GithubOutlined: '宠物知识',
    DollarOutlined: '自助狗狗',
    MessageOutlined: '预约上课',
};