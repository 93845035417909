import axios from 'axios'
import qs from 'qs'
import manageUrl from '@/http/manage'
import { useHomeStore } from "../store";
import data from "../router"
const router = data.router

// console.log(manageUrl.baseUrl)
axios.defaults.timeout = 50000;  //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json';  //配置请求头
axios.defaults.baseURL = manageUrl.baseUrl;             // 配置接口地址
// axios.defaults.baseURL = 'http://10.0.51.81:5002/api';  // 本机联调接口
// axios.defaults.withCredentials = true

// 请求拦截器-----------------------------------------------------
axios.interceptors.request.use((config) => {
  // 在发送请求之前做某件事
  if (Object.prototype.toString.call(config.data) === '[object FormData]') {
    var jsonData = {};
    config.data.forEach((value, key) => {
      jsonData[key] = value
    });
    if (
      config.url == "api/data/save" && 'file_img' in jsonData
    ) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
  }
  // 如果有token就加token
  const store = useHomeStore()
  let token = store.token
  config.headers['Authorization'] = token && token != 'null' ? token : ''


  if (sessionStorage.getItem("passkey") && sessionStorage.getItem("passkey").indexOf('空') < 0) {
    config.headers['passkey'] = sessionStorage.getItem("passkey")
  }

  return config;
}, (error) => {
  console.log('fail:错误的传参');
  return Promise.reject(error);
});

// 响应拦截器-----------------------------------------------------
axios.interceptors.response.use((res) => {
  // 对响应数据做些事
  // token失效
  if (res.data.code == 2) {
    const store = useHomeStore()
    // 退出登录
    store.changeToken(null)
    store.changeUser(null)
    router.push("/login");
  }
  return res;
}, (error) => {
  return Promise.reject(error);
});

// (封装post请求)--------------------------------------------------
export function fetchPost(url, params, isloading = true) {
  if (isloading) {
    // console.log(isloading)
  }
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then((res) => {
        if (res.data.code == 0) {
          resolve(res.data);
          return false
        } else {
          reject(res.data);
          // ***.error('网络错误')
        }
      }, err => {
        reject(err);
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// (封装get请求)---------------------------------------------------
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: param })
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}