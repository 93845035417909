const server = {
    path: "/home",
    name: "home",
    component: () => import("../views/home.vue"),
    redirect: "/dogWeb",
    meta: { title: "首页", },
    children: [
        {
            path: '/dogWeb',
            name: 'dogWeb',
            component: () => import('../views/home/dogWeb.vue'),
            meta: {
                title: "首页",
                icon: "HomeOutlined",
                class: 'home'
            },
        },
        {
            path: '/aboutUs',
            name: 'aboutUs',
            component: () => import('../views/home/aboutUs.vue'),
            meta: {
                title: "关于我们",
                icon: "ShopOutlined",
                class: 'home'
            },
        },
        {
            path: '/salvageCase',
            name: 'salvageCase',
            component: () => import('../views/home/salvageCase.vue'),
            meta: {
                title: "救助案例",
                icon: "FileDoneOutlined",
                class: 'home'
            },
        },
        {
            path: '/petNutrition',
            name: 'petNutrition',
            component: () => import('../views/home/petNutrition.vue'),
            meta: {
                title: "宠物知识",
                icon: "GithubOutlined",
                class: 'home'
            },
        },
        {
            path: '/sponsorDogs',
            name: 'sponsorDogs',
            component: () => import('../views/home/sponsorDogs.vue'),
            meta: {
                title: "资助狗狗",
                icon: "DollarOutlined",
                class: 'home'
            },
        },
        {
            path: '/bookClass',
            name: 'bookClass',
            component: () => import('../views/home/bookClass.vue'),
            meta: {
                title: "预约上课",
                icon: "MessageOutlined",
                class: 'home'
            },
        }
    ]
};

export default server;
