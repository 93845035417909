import { RouteRecordRaw, createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../components/404.vue')
  },
]

// -----------------------
// 动态新增路由 
import home from './home'
const asyncRoutes = [
  home,
  // 404 page must be placed at the end !!!
  { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
]

const router = {
  router: createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  }),
  asyncRoutes: asyncRoutes
}

export default router
