export default {
    // 测试
    listButton: 'Button',
    // ment
    HomeOutlined: 'Home',
    ShopOutlined: 'About',
    FileDoneOutlined: 'Case',
    GithubOutlined: 'Knowledge',
    DollarOutlined: 'Subsidize',
    MessageOutlined: 'Booking',
};