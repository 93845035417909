export default {
    outimgList: "v1/outimg/list", // 测试接口  （需要登陆）

    // 登录页面----------------------------------------
    getInfo: "api/user/info", // 用户信息
    login: "v1/user/login", // 账号密码登录
    getsmscode: "api/user/get_smscode", // 发送短信验证码
    loginsms: "api/user/login_sms", // 短信验证码登录
    // ***页面-----------------------------------------
    getWebData: "api/data/list", // 获取网站信息
    setDataSave: "api/data/save", // 保存网站信息 
    sendEmail: "api/email/send", // 发送邮件 
}