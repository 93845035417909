import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// 引入ant-design-vue;
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

// axios请求
import manageUrl from './http/manage.js'
import { fetchPost, fetchGet } from './http/httpRequest.js'
app.config.globalProperties.$manageUrl = manageUrl
app.config.globalProperties.$http = {
  POST: fetchPost,
  GET: fetchGet,
}

// pinia
import { createPinia } from 'pinia' // ① 引入createPinia方法从pinia
import { createPersistedState } from 'pinia-persistedstate-plugin' // 数据持久化插件
const pinia = createPinia(); // ② 拿到pinia实例
pinia.use(createPersistedState())

// mixin 
import commonMixin from "./utils/common.js"

// 路由 
import data from "./router"
let router = data.router
let asyncRoutes = data.asyncRoutes
// 动态添加可访问路由表
asyncRoutes.forEach((item: any) => {
  router.addRoute(item)
})

import { message } from "ant-design-vue";
// 全局路由守卫
import { useHomeStore } from "./store"; // pinia 取值与赋值
router.beforeEach((to, from, next) => {
  const homeStore = useHomeStore();
  next();

  // // 有token
  // if (homeStore.token && homeStore.user) {
  //   // 去登录页则直接去首页
  //   if (to.path === "/login") {
  //     next({ path: "/" });
  //   } else {
  //     next();
  //   }
  //   // 没token
  // } else {
  //   if (to.path === "/" || to.path === "/login") {
  //     next();
  //   } else {
  //     // 不去登录页也必须去登录页
  //     next(`/?redirect=${to.path}`);
  //   }
  // }
});

// 无限滚动
import infiniteScroll from 'vue3-infinite-scroll-better'

// 国际化 通过插件的形式挂载
import i18n from './language/index'


app.use(router)
  .use(infiniteScroll)
  .use(Antd)
  .use(pinia)
  .use(commonMixin)
  .use(i18n)
  .mount('#app')
