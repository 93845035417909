<template>
  <a-style-provider hash-priority="high">
    <a-config-provider :locale="locale">
      <router-view />
      <contextHolder />
    </a-config-provider>
  </a-style-provider>
</template>
<script>
// app.vue写在script里面  main.js写在app挂载完之后
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};

import zh from "ant-design-vue/es/locale/zh_CN";
import tc from "ant-design-vue/es/locale/zh_TW";
import en from "ant-design-vue/es/locale/en_US";

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";

// hash-priority="high"时候message没有去掉:where，所以加上这段
import { message } from "ant-design-vue";
const [messageApi, contextHolder] = message.useMessage();
messageApi.open({});
export default {
  components: {
    contextHolder,
  },
  name: "App",
  data() {
    return {};
  },
  watch: {},
  props: {},
  created() {
    sessionStorage.getItem("lang")
      ? (this.$i18n.locale = sessionStorage.getItem("lang"))
      : (this.$i18n.locale = "zh");
  },
  mounted() {
    if (this.token && this.user) {
      // this.getInfo();
    }
  },
  computed: {
    locale() {
      if (this.$i18n.locale == "zh") {
        console.log("1 zh语");
        dayjs.locale("zh-cn");
        return zh;
      } else if (this.$i18n.locale == "tc") {
        console.log("2 tc语");
        dayjs.locale("zh-tw");
        return tc;
      } else if (this.$i18n.locale == "en") {
        console.log("3 en语");
        dayjs.locale("en");
        return en;
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss">
@import "@/utils/common.scss";

* {
  margin: 0;
  padding: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: white;
  background-color: rgb(242, 245, 249);
  font-family: PingFang SC, PingFang SC;
}

// 修改滚动条
::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border-style: dashed;
  border-color: transparent;
  border-width: 3px;
  background: rgba(157, 165, 183, 0.2);
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 165, 183, 0.7);
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
